<template>
  <div class="container-box">
    <b-row class="no-gutters px-3 px-sm-0 align-items-center">
      <b-col lg="6">
        <h4 class="mr-sm-4 header-tablepage">
          {{ title }}
        </h4>
      </b-col>
      <b-col lg="6" class="text-right">
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            @keyup.enter="handleSearch"
            :placeholder="placeholder"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
          <b-button @click.prevent="sidebarFilter" class="btn-filter ml-2">
            <font-awesome-icon icon="filter" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="filter" class="pointer" />
              Filter
            </span>
          </b-button>
          <b-dropdown id="dropdown" text="Options" class="ml-2">
            <b-dropdown-item @click="importFile"
              >Import Product</b-dropdown-item
            >
            <b-dropdown-item @click="exportFile"
              >Export (.xlsx)</b-dropdown-item
            >
            <b-dropdown-item @click="downLoadTemplate"
              >Download Template</b-dropdown-item
            >
          </b-dropdown>
          <b-button
            @click.prevent="createProduct"
            class="btn button btn-mobile ml-2"
          >
            <font-awesome-icon
              icon="plus-square"
              class="text-black d-sm-none"
            />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="plus-square" class="pointer" />
              Create New
            </span>
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      required: true,
      type: String,
    },
    filter: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
  },
  methods: {
    handleSearch() {
      this.$emit("handleSearch", this.filter.search);
    },
    sidebarFilter() {
      this.$emit("sidebarFilter");
    },
    createProduct() {
      this.$emit("createProduct");
    },
    downLoadTemplate() {
      this.$emit("downloadTemplate");
    },
    exportFile() {
      this.$emit("exportFile");
    },
    importFile() {
      this.$emit("importFile");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
}
.form-control:focus {
  border-color: var(--primary-color)
}
.btn-create {
  color: #fff;
  background-color: var(--primary-color);
  font-weight: 100;
  width: 125px;
}
::v-deep .dropdown-toggle.btn-secondary {
  background-color: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>
