<template>
  <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
    <div class="mt-3">
      <b-table
        ref="tablecustom"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(image_url)="data">
          <div
            v-if="data.item.image_url"
            class="position-relative image"
            v-bind:style="{
              'background-image': 'url(' + data.item.image_url + ')',
            }"
          ></div>
          <div
            v-else
            class="position-relative image"
            v-bind:style="{
              backgroundImage: 'url(/img/default-placeholder.png)',
            }"
          ></div>
        </template>
        <template v-slot:cell(name)="data">
          <div class="text-left">
            <div class="product-name pointer" @click="showDetail(data.item)">
              {{ data.item.name ? data.item.name : "-" }}
            </div>
            <div>
              {{ data.item.article_no }}
              <span class="article-type">{{ data.item.article_type }}</span>
            </div>
          </div>
        </template>
        <template v-slot:cell(price)="data">
          <div>{{ data.item.price | numeral("0,0.00") }}</div>
        </template>
        <template v-slot:cell(barcode)="data">
          <div>{{ data.item.barcode ? data.item.barcode : "-" }}</div>
        </template>
        <template v-slot:cell(status)="data">
          <div v-if="data.item.status === 1" class="consent-allow">
            เปิดการใช้งาน
          </div>
          <div v-else class="status-cancel">ปิดการใช้งาน</div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <!-- <b-row class="px-2">
      <b-col cols="12" md="5" class="form-inline justify-content-start">
        <p class="mb-0 p-gray">
          Showing {{ showings }} to {{ toShow }} of {{ rows }} entires
        </p>
      </b-col>
      <b-col cols="12" md="7" class="form-inline justify-content-end">
        <div>
          <b-pagination
            v-model="filter.page"
            :total-rows="rows"
            :per-page="filter.take"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            class="m-md-0"
            @change="pagination"
          ></b-pagination>
        </div>
        <div class="ml-2">
          <b-form-select
            v-model="filter.take"
            @change="handleChangeTake"
            :options="pageOptions"
          ></b-form-select>
        </div>
      </b-col>
    </b-row> -->
    <Pagination
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
      @handleChangeTake="handleChangeTake"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
      showings: this.showing,
    };
  },
  methods: {
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showings = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showings = value * this.filter.take - this.filter.take;
        this.toShow = this.showings + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("handleChangeTake", this.filter);
    },
    showDetail(item) {
      this.$emit("showDetail", item.product_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.article-type {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-weight: 100;
  border-radius: 20px;
  font-size: 90%;
  padding: 0 10px;
}
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.consent-allow {
  color: #06c306;
}
.status-cancel {
  color: red;
}
.product-name {
  text-decoration: underline;
  color: #050505;
}
</style>
